import React from "react";
import { InfinitySpin, LineWave, Triangle } from "react-loader-spinner";

const AppLoader = ({color ='#4fa94d' , textColor="black" }) => {
    return (
        <div className="flex items-center justify-center text-center ">
            <div className="flex flex-col items-center">
                <div className="ml-5">

                <LineWave
                    visible={true}
                    height="100"
                    width="100"
                    color={color}
                    ariaLabel="line-wave-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    firstLineColor=""
                    middleLineColor=""
                    lastLineColor=""
                    />
                    </div>
                <p className="mt-1 text-center" style={{color:textColor}}>Loading</p>
            </div>
        </div>
    )
}

export default AppLoader;