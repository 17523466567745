import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid'

 function CalendarView({events, eventClick, height ='35rem'}) {
  return (

    // Basically if u want to show the event as a bar just pass the date like this "date":"2024-08-1" or "start":"2024-08-1" 
    // if u want to show the event as a dot pass time like this "start":"2024-08-1T03:40" 
    <FullCalendar
  plugins={[ dayGridPlugin, timeGridPlugin ]}
  initialView="dayGridMonth"
  displayEventTime={true}
  weekends={true}
  height={height}
  // eventClick={(e)=>{console.log(e.event._def);}}
  eventClick={eventClick}
  headerToolbar={{
    left: 'prev,next',
    center: 'title',
    right: 'dayGridMonth', // user can switch between the two
  }}
  events={events}
/>
  )
}
export default CalendarView