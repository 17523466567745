import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useData } from "../../Context/DataContext";
import UserAvatar from "../User-Avatar/UserAvatar";

const ListItem = ({ children, NavLink }) => {
  const handleClick = (e) => {
    if(NavLink == 'signout')
      return e.preventDefault();
    if (NavLink.startsWith("/#")) {
      e.preventDefault();
      const targetId = NavLink.substring(2);
      const element = document.getElementById(targetId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <li>
      <Link
        style={{ color: 'black' }}
        to={NavLink}
        onClick={handleClick}
        className="flex py-2 text-base font-medium text-light hover:text-primary lg:ml-10 lg:inline-flex"
      >
        {children}
      </Link>
    </li>
  );
};

const AppNavbar = () => {
  const navigate = useNavigate();
  const { contextData, setContextData } = useData();
  const [open, setOpen] = useState(false);
  const signOut = async () => {
    setContextData({});
    localStorage.removeItem('token');
    localStorage.removeItem('contextData');
    window.location.href = '/';
  };
  return (
    <header className={`absolute left-0 top-0 z-20 flex w-full items-center`}>
      <div className="container">
        <div className="relative mx-4 flex items-center justify-between">
          <div className="w-60 max-w-full px-4">
            <Link to="/" className="block w-full">
              <img src="/logo.png" alt="logo" className="w-full light:hidden" />
            </Link>
          </div>
          <div className="flex w-full items-center justify-between px-4">
            <div>
              <button
                onClick={() => setOpen(!open)}
                id="navbarToggler"
                className={`${open && "navbarTogglerActive"
                  } absolute right-4 top-1/2 block -translate-y-1/2 rounded-lg px-3 py-[6px] ring-primary focus:ring-2 lg:hidden`}
              >
                <span className="relative my-[6px] block h-[2px] w-[30px] bg-body-color light:bg-white"></span>
                <span className="relative my-[6px] block h-[2px] w-[30px] bg-body-color light:bg-white"></span>
                <span className="relative my-[6px] block h-[2px] w-[30px] bg-body-color light:bg-white"></span>
              </button>
              <nav
                id="navbarCollapse"
                className={`absolute right-4 top-full w-full max-w-[250px] rounded-lg bg-white px-6 py-5 shadow light:bg-light-2 lg:static lg:block lg:w-full lg:max-w-full lg:bg-transparent lg:shadow-none lg:light:bg-transparent ${!open && "hidden"
                  }`}
              >
                <ul className="block lg:flex">
                  <ListItem NavLink="/">Home</ListItem>
                  <ListItem NavLink="/#about">About</ListItem>
                  <ListItem NavLink="/#services">Services</ListItem>
                  
                  {contextData.loggedIn &&
                    <>
                      <ListItem NavLink="/reservations">Reservations</ListItem>
                      {contextData.user.role == 'admin' &&
                    <>
                      <ListItem NavLink="/admin/dashboard">View Dashboard</ListItem>
                      
                    </>
                  }
                      <ListItem NavLink={'signout'}>
                        <button onClick={signOut}>Sign out</button>
                      </ListItem>
                    </>
                  }
                  
                  {!contextData.loggedIn && (
                    <>
                    <div className="flex flex-col lg:hidden">

                      <ListItem NavLink="/sign-in">Sign in</ListItem>
                      <ListItem NavLink="/sign-up">Sign Up</ListItem>
                    </div>
                    </>
                  )}
                </ul>
              </nav>
            </div>
            <div className="hidden justify-end pr-16 sm:flex lg:pr-0">
              {/* {!contextData.loggedIn && (
                <>
                  <ListItem NavLink="/sign-in">Sign in</ListItem>
                  <ListItem NavLink="/sign-up">Sign Up</ListItem>
                </>
              )} */}
              {contextData.loggedIn ? (
                <UserAvatar />
              ) : (
                <>
                  <Link
                    style={{ color: 'black' }}
                    to="/sign-in"
                    className="px-7 py-3 text-base font-medium text-light hover:text-primary light:text-white"
                  >
                    Sign in
                  </Link>

                  <Link
                    to="/sign-up"
                    className="rounded-lg bg-primary px-7 py-3 text-base font-medium text-white hover:bg-opacity-90"
                  >
                    Sign Up
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {open && contextData.loggedIn && (
        <div className="absolute right-4 top-[50px] bg-white p-4 rounded shadow-md lg:hidden">
          <UserAvatar />
        </div>
      )}
    </header>
  );
};

export default AppNavbar;