import React, { useEffect, useState } from "react";
import { FaRegUserCircle } from "react-icons/fa";
import Joi from "joi-browser";
import { useAppRequests } from "../../Network/Requests";
import { LineWave } from "react-loader-spinner";
import { useData } from "../../Context/DataContext";
import { Link } from "react-router-dom";
import OtpInput from "../../components/OTP-Input/OtpInput";

const ForgetPasswordPage = () => {
    const { unsecuredPost } = useAppRequests();
    const { contextData } = useData();
    const [data, setData] = useState({ email: '', password: '' });
    const [submitBtnText, setSubmitBtnText] = useState('Request OTP');
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [otp, setOtp] = useState(Array(5).fill(""));
    const [otpShow, setOtpShow] = useState(false);
    const [submissionMessage, setSubmissionMessage] = useState({ message: '', type: 1 }); // 1 for success, 0 for error

    useEffect(() => {
        setData((prevData) => ({
            ...prevData,
            otp: otp.join("") // Convert OTP array to string
        }));
    }, [otp]);


    const validateForm = () => {
        const validationResult = Joi.validate(data, schema, { abortEarly: false });
        if (validationResult.error) {
            const errorMessages = {};
            validationResult.error.details.forEach(detail => {
                errorMessages[detail.path[0]] = detail.message;
            });
            setErrors(errorMessages);
            return { success: false, errorMessages };
        }
        setErrors({});
        return { success: true };
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validateData = validateForm();
        if (validateData.success) {
            setLoading(true);
            if (otpShow) {
                
                setLoading(true);
                // If OTP is shown, it's time to update the password
                const updateData = { email: data.email, password: data.password, otp: otp.join("") };
                const response = await unsecuredPost('/update-password', updateData);
                console.log(response);
                
                
                if (!response.success) {
                    setSubmissionMessage({ message: response.response.error, type: 0 });
                } else {
                    setSubmissionMessage({ message: response.response.message, type: 1 });
                }
            } else {
                // Otherwise, request the OTP
                const response = await unsecuredPost('/request-otp', data);
                if (!response.success) {
                    setSubmissionMessage({ message: response.response.error, type: 0 });
                } else {
                    setOtpShow(true);
                    setSubmitBtnText('Change Password');
                    setSubmissionMessage({ message: response.response.message, type: 1 });
                }
            }
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    };

    const schema = {
        email: Joi.string().email().label('Email').required(),
        password: otpShow ? Joi.string().min(8).label('New Password').required() : Joi.optional(),
        otp: otpShow ? Joi.string().length(5).label('OTP').required() : Joi.optional()
    };

    return (
        <>
            <div className="flex min-h-full mt-20 flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm text-center">
                    <div className="flex justify-center">
                        <FaRegUserCircle className="text-6xl text-primary" />
                    </div>
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        {otpShow ? 'Change Password' : 'Request OTP'}
                    </h2>
                </div>

                {loading ? (
                    <div className="flex items-center justify-center text-center">
                        <LineWave
                            visible={true}
                            height="100"
                            width="100"
                            color="#4fa94d"
                            ariaLabel="line-wave-loading"
                        />
                    </div>
                ) : (
                    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                        {submissionMessage.message && (
                            <div
                                className={`text-sm text-center ${submissionMessage.type === 1 ? 'text-green-500' : 'text-red-500'}`}
                            >
                                {submissionMessage.message}
                            </div>
                        )}

                        <div className="my-2">
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                Email address
                            </label>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    required
                                    autoComplete="email"
                                    value={data.email}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            {errors.email && <div className="text-red-500 text-sm">{errors.email}</div>}
                        </div>

                        {otpShow && (
                            <>
                                <div className="my-2">
                                    <label htmlFor="otp" className="block text-sm font-medium leading-6 text-gray-900">
                                        OTP
                                    </label>
                                    <div className="mt-2">
                                        <OtpInput otp={otp} setOtp={setOtp} />
                                    </div>
                                    {errors.otp && <div className="text-red-500 text-sm">{errors.otp}</div>}
                                </div>

                                <div className="my-2">
                                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                        New Password
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="password"
                                            name="password"
                                            type="password"
                                            value={data.password}
                                            required
                                            onChange={(e) => handleChange(e)}
                                            autoComplete="current-password"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                    {errors.password && <div className="text-red-500 text-sm">{errors.password}</div>}
                                </div>
                            </>
                        )}

                        <div>
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 my-4 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
                                onClick={handleSubmit}
                            >
                                {submitBtnText}
                            </button>
                        </div>

                        <p className="mt-10 text-center text-sm text-gray-500">
                            Remembered your password?{' '}
                            <Link to="/sign-in" className="font-semibold leading-6 text-primary hover:text-primary-500">
                                Sign in
                            </Link>
                        </p>
                    </div>
                )}
            </div>
        </>
    );
};

export default ForgetPasswordPage;