import React, { useEffect, useState } from "react";
import { AppSidebar } from "../../../components/App-Sidebar/AppSidebar";
import Statistic from "../../../components/Statistic/Statistic";

import CalendarView from "../../../components/CalendarView/CalendarView";
import './style.css';
import { useAppRequests } from "../../../Network/Requests";
import AppLoader from "../../../components/App-Loader/AppLoader";
import AppTable from "../../../components/App-Table/AppTable"; // Import AppTable

const DashboardPage = () => {
    const [reservationMessage, setReservationMessage] = useState({
        message: '',
        type: 1 // 1 for success, 0 for error
    });
    const [activeFilter, setActiveFilter] = useState('all');
    const [events, setEvents] = useState([]);
    const [viewMode, setViewMode] = useState(1); // 1 for Table View, 2 for Calendar View
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalPages: 1
    });
    const { securedGet, securedPut, isLoading } = useAppRequests();
    const [stats, setStats] = useState({});

    useEffect(() => {
        const fetchStatsData = async () => {
            const response = await securedGet('/stats', 'stats');
            if (response.success)
                setStats(response.response.data);
        };
        fetchStatsData();
        fetchReservations();
    }, []);

    const fetchReservations = async (page = 1, status = '') => {
        const endpoint = status ? `/all-reservations?status=${status}&page=${page}` : `/all-reservations?page=${page}`;
        const response = await securedGet(endpoint, 'reservations');
        if (response.success) {

            const formattedEvents = response.response.data.data.map(reservation => ({
                id: reservation.id,
                service: reservation.service.name,
                title: reservation.service.name,
                patient: reservation.user
                    ? `${reservation.user.first_name} ${reservation.user.last_name}`
                    : `${reservation.g_first_name} ${reservation.g_last_name}`,
                date: `${reservation.date}T${reservation.time}`,
                time: `${reservation.time}`,
                status: reservation.status,
                color: getStatusColor(reservation.status),
                patientInfo: {
                    'name':
                        reservation.user ? `${reservation.user.first_name} ${reservation.user.last_name}`
                            : `${reservation.g_first_name} ${reservation.g_last_name}`,
                    'phone': reservation.user ? reservation.user.phone : reservation.g_phone,
                    'email': reservation.user ? reservation.user.email : reservation.g_email,
                },
            }));
            setEvents(formattedEvents);
            setPagination({
                currentPage: response.response.data.current_page,
                totalPages: response.response.data.last_page
            });
        }
    };

    const handleFilterChange = (status) => {
        setReservationMessage({});
        setActiveFilter(status);
        fetchReservations(1, status === 'all' ? '' : status);
    };

    const handleViewButton = (int) => {
        setViewMode(int);
    };

    const handlePreviousPage = () => {
        if (pagination.currentPage > 1) {
            fetchReservations(pagination.currentPage - 1, activeFilter === 'all' ? '' : activeFilter);
        }
    };

    const handleNextPage = () => {
        if (pagination.currentPage < pagination.totalPages) {
            fetchReservations(pagination.currentPage + 1, activeFilter === 'all' ? '' : activeFilter);
        }
    };

    const handleMarkCompleted = async (e) => {
        const body = {
            reservationId: e.id,
            status: 'completed'
        }
        const response = await securedPut('/update-reservation-status', body, 'reservations');
        if (response.success) {
            setReservationMessage({
                message: response.response.message,
                type: 1
            })
            fetchReservations(pagination.currentPage, activeFilter === 'all' ? '' : activeFilter);
        }
        else {
            setReservationMessage({
                message: response.response.error,
                type: 0
            })
        }
    }
    const handleMarkCancelled = async(e) => {
        const body = {
            reservationId: e.id,
            status: 'cancelled'
        }
        const response = await securedPut('/update-reservation-status', body , 'reservations');
        if (response.success) {
            setReservationMessage({
                message: response.response.message,
                type: 1
            })
            fetchReservations(pagination.currentPage, activeFilter === 'all'? '' : activeFilter);
        }
        else {
            setReservationMessage({
                message: response.response.error,
                type: 0
            })
        }
        
    }

    function getStatusColor(status) {
        switch (status) {
            case 'cancelled': return 'rgb(239 68 68)';
            case 'completed': return 'rgb(34 197 94)';
            case 'confirmed': return 'rgb(59 130 246)';
            default: return 'gray';
        }
    }

    return (
        <div className="flex h-screen">
            <div className="h-full">

                <AppSidebar activeTab={'dashboard'} />
            </div>
            <div className="flex-grow p-6 bg-white">
                <div className="Statistics m-5 flex items-center justify-center">
                    {isLoading('stats') ? (
                        <AppLoader />
                    ) : (
                        <>
                            <Statistic icon={<img alt="statisric-img" src="/users-icon.png" width={30} height={30} />} title={'Total Users'} desc={stats.totalUsers} />
                            <Statistic icon={<img alt="statisric-img" src="/reservations.png" width={30} height={30} />} title={'Total Reservations'} desc={stats.totalReservations} />
                            <Statistic icon={<img alt="statisric-img" src="/upcoming-reservations.png" width={30} height={30} />} title={'Upcoming Reservations'} desc={stats.upcomingReservations} />
                        </>
                    )}
                </div>

                <div className="flex items-center m-5 justify-center">
                    <button
                        onClick={() => handleViewButton(1)}
                        className={`
                            ${viewMode === 1 ? 'bg-green-500 text-white border-transparent' : 'bg-transparent text-green-700 border-green-500'}
                            m-2 font-semibold py-2 px-4 border rounded hover:bg-green-500 hover:text-white hover:border-transparent
                        `}
                    >
                        Table View
                    </button>
                    <button
                        onClick={() => handleViewButton(2)}
                        className={`
                            ${viewMode === 2 ? 'bg-green-500 text-white border-transparent' : 'bg-transparent text-green-700 border-green-500'}
                            m-2 font-semibold py-2 px-4 border rounded hover:bg-green-500 hover:text-white hover:border-transparent
                        `}
                    >
                        Calendar View
                    </button>
                </div>

                <div className="calendarView">
                    <div className="flex justify-center mb-5">
                        {['cancelled', 'completed', 'confirmed', 'all'].map((status) => (
                            <div
                                key={status}
                                onClick={() => handleFilterChange(status)}
                                className={`calendarKey flex items-center mr-4 hover:bg-gray-200 px-5 py-2 rounded ${activeFilter === status ? 'active' : ''}`}
                            >
                                {status !== 'all' && <div className={`w-4 h-4 mr-2 bg-${status === 'cancelled' ? 'red' : status === 'completed' ? 'green' : 'blue'}-500`}></div>}
                                <span>{status.charAt(0).toUpperCase() + status.slice(1)}</span>
                            </div>
                        ))}
                    </div>

                    {isLoading('reservations') ? (
                        <AppLoader />
                    ) : (
                        <>
                            {viewMode === 1 ? (
                                <>
                                    {reservationMessage.message && (
                                        <div
                                            className={`text-sm text-center ${reservationMessage.type === 1 ? 'text-green-500' : 'text-red-500'
                                                }`}
                                        >
                                            {reservationMessage.message}
                                        </div>
                                    )}
                                    <AppTable
                                        columns={['service', 'date', 'time', 'status', 'patient']}
                                        data={events}
                                        adminTable={true}
                                        cancelClick={(e) => { console.log(e) }}
                                        markCompleted={handleMarkCompleted}
                                        markCancelled={handleMarkCancelled}
                                    />
                                </>
                            ) : (
                                <CalendarView events={events} height="30rem" />
                            )}
                            <div className="flex justify-center mt-5">
                                <button
                                    onClick={handlePreviousPage}
                                    disabled={pagination.currentPage === 1}
                                    className="mx-2 px-4 py-2 bg-green-500 text-white rounded disabled:bg-gray-300"
                                >
                                    &#8592; Previous
                                </button>
                                <span className="mx-2 py-2">
                                    Page {pagination.currentPage} of {pagination.totalPages}
                                </span>
                                <button
                                    onClick={handleNextPage}
                                    disabled={pagination.currentPage === pagination.totalPages}
                                    className="mx-2 px-4 py-2 bg-green-500 text-white rounded disabled:bg-gray-300"
                                >
                                    Next &#8594;
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default DashboardPage;