import React, { useState, useEffect } from "react";
import { AppSidebar } from "../../../components/App-Sidebar/AppSidebar";

import { useAppRequests } from "../../../Network/Requests";
import AppLoader from "../../../components/App-Loader/AppLoader";

const ConfigurationsPage = () => {
    const [workingHours, setWorkingHours] = useState({
        start: 0,
        end: 0
    });
    const [configurationMessage, setConfigurationsMessage] = useState({
        message: '',
        type: 1 // 1 for success, 0 for error
    });
    const [clinicCapacityPerHour, setClinicCapacityPerHour] = useState(0);
    const [insuranceCompanies, setInsuranceCompanies] = useState([]);
    const [configurations, setConfigurations] = useState({});
    const { securedGet, securedPut, securedDelete, isLoading } = useAppRequests(); // Added securedDelete for deletion
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentCompanyIndex, setCurrentCompanyIndex] = useState(null);
    const [currentCompanyName, setCurrentCompanyName] = useState("");

    const [originalWorkingHours, setOriginalWorkingHours] = useState({});
    const [originalClinicCapacity, setOriginalClinicCapacity] = useState(0);

    useEffect(() => {
        const getConfigurations = async () => {
            const response = await securedGet('/configurations', 'configurations');
            if (response.success) {
                const data = response.response.data;
                setConfigurations({
                    workingHours: data.workingHours,
                    clinicCapacity: data.clinicCapacity,
                    insuranceCompanies: data.insuranceCompanies
                });
                setWorkingHours(data.workingHours);
                setClinicCapacityPerHour(data.clinicCapacity);
                setInsuranceCompanies(data.insuranceCompanies);

                setOriginalWorkingHours({
                    start: data.workingHours.start,
                    end: data.workingHours.end
                });
                setOriginalClinicCapacity(data.clinicCapacity);
            }
        };
        getConfigurations();
    }, []);

    const handleInputChange = (setter, field, value, limit = {}) => {
        value = Math.max(limit.min || 0, Math.min(limit.max || 100, value));
        if (field == null) {
            setter(value);
        } else {
            setter(prevState => ({
                ...prevState,
                [field]: value,
            }));
        }
    };

    const handleSave = async (data, endpoint) => {
        const response = await securedPut(endpoint, data, 'configurations');
        if (response.success) {
            setConfigurationsMessage({ message: response.response.message, type: 1 });
        } else {
            setConfigurationsMessage({ message: response.response.error, type: 0 });
        }
    };

    const openModal = (index, company) => {
        setCurrentCompanyIndex(index);
        setCurrentCompanyName(company);
        setIsModalOpen(true);
    };

    const handleModalSave = async () => {
        if (currentCompanyIndex !== null) {
            const companyId = insuranceCompanies[currentCompanyIndex].id; // Assuming company has an id
            const response = await securedPut(`/insurance-company`, { name: currentCompanyName , insurance_company_id:companyId }, 'companies');
            if (response.success) {
                setConfigurationsMessage({ message: response.response.message, type: 1 });
                const updatedCompanies = [...insuranceCompanies];
                updatedCompanies[currentCompanyIndex].name = currentCompanyName;
                setInsuranceCompanies(updatedCompanies);
            } else {
                setConfigurationsMessage({ message: response.response.error, type: 0 });
            }
        }
        setIsModalOpen(false);
    };

    const handleEditCompany = (index) => {
        const company = insuranceCompanies[index];
        console.log("Edit Company:", company);
        // Perform your edit logic here, like opening a modal or an edit form
        openModal(index, company.name);
    };

    const handleRemoveCompany = async (index) => {
        const company = insuranceCompanies[index];
        console.log("Remove Company:", company);
        // Perform your delete logic here, like making an API call to remove the company
        const response = await securedDelete(`/remove-insurance-company?insurance_company_id=${company.id}`, 'companies');
        if (response.success) {
            setConfigurationsMessage({ message: response.response.message, type: 1 });
            setInsuranceCompanies(insuranceCompanies.filter((_, i) => i !== index));
        } else {
            setConfigurationsMessage({ message: response.response.error, type: 0 });
        }
    };

    const hasWorkingHoursChanged = workingHours.start !== originalWorkingHours.start || workingHours.end !== originalWorkingHours.end;
    const hasClinicCapacityChanged = clinicCapacityPerHour !== originalClinicCapacity;

    return (
        <div className="flex h-screen">
            <AppSidebar activeTab={'configurations'} />
            <div className="flex-grow p-6 bg-white">
                <h1 className="text-2xl font-bold mb-6">System Configurations</h1>

                <div className="space-y-5">
                    {configurationMessage.message && (
                        <div
                            className={`text-sm text-center ${configurationMessage.type === 1 ? 'text-green-500' : 'text-red-500'
                                }`}
                        >
                            {configurationMessage.message}
                        </div>
                    )}
                    {isLoading('configurations') ? (
                        <AppLoader  />
                    ) : (
                        <div className="flex space-x-4 items-center justify-center">
                            <div className="w-1/2">
                                <h2 className="text-lg font-semibold mb-2">Working Hours</h2>
                                <div className="flex space-x-4">
                                    <div className="flex flex-col items-center">
                                        <label className="block text-sm font-medium text-gray-700">Start Time</label>
                                        <input
                                            type="number"
                                            min={0}
                                            max={24}
                                            value={workingHours.start}
                                            onChange={(e) => handleInputChange(setWorkingHours, 'start', e.target.value, { min: 0, max: 24 })}
                                            className="mt-1 w-20 text-center py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-secondary focus:border-secondary sm:text-sm"
                                        />
                                    </div>
                                    <div className="flex flex-col items-center">
                                        <label className="block text-sm font-medium text-gray-700">End Time</label>
                                        <input
                                            type="number"
                                            min={0}
                                            max={24}
                                            value={workingHours.end}
                                            onChange={(e) => handleInputChange(setWorkingHours, 'end', e.target.value, { min: 0, max: 24 })}
                                            className="mt-1 w-20 text-center py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-secondary focus:border-secondary sm:text-sm"
                                        />
                                    </div>
                                    {hasWorkingHoursChanged ? (
                                        <button
                                            onClick={() => handleSave({ start_time: parseInt(workingHours.start), end_time: parseInt(workingHours.end) }, '/working-hours')}
                                            className="mt-5 inline-flex items-center px-4 bg-secondary text-white text-sm font-medium rounded-md shadow-sm hover:bg-secondary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
                                        >
                                            Save Working Hours
                                        </button>
                                    ) : null}
                                </div>
                            </div>

                            <div className="w-1/2">
                                <label className="block text-lg font-semibold text-gray-700">Clinic Capacity Per Hour</label>
                                <p className="text-xs text-gray-400">Clinic Capacity refers to the number of patients the clinic can handle per hour</p>
                                <div className="flex mt-3 items-center ">
                                    <input
                                        type="number"
                                        value={clinicCapacityPerHour}
                                        onChange={(e) => handleInputChange(setClinicCapacityPerHour, null, e.target.value, { min: 1, max: 50 })}
                                        className="mt-1 block w-1/5 text-center border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-secondary focus:border-secondary sm:text-sm"
                                    />
                                    {hasClinicCapacityChanged ? (
                                        <button
                                            onClick={() => handleSave({ capacity: clinicCapacityPerHour }, '/clinic-capacity')}
                                            className="mx-4 items-center justify-center px-4 py-2 bg-secondary text-white text-sm font-medium rounded-md shadow-sm hover:bg-secondary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
                                        >
                                            Save Clinic Capacity
                                        </button>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="my-5">
                        <h2 className="text-lg font-semibold mb-2">Insurance Companies</h2>
                        {isLoading('configurations') || isLoading('companies') ? (
                            <AppLoader  />
                        ) : (
                            <div className="grid grid-cols-3 gap-5">
                                {insuranceCompanies.map((company, index) => (
                                    <div key={index} className="bg-white p-4 rounded-md shadow-md flex justify-between items-center">
                                        <div>{company.name}</div>
                                        <div className="flex space-x-2">
                                            <button
                                                onClick={() => handleEditCompany(index)}
                                                className="bg-blue-400 rounded p-3 text-white  hover:bg-blue-500"
                                            >
                                                Edit
                                            </button>
                                            <button
                                                onClick={() => handleRemoveCompany(index)}
                                                className="bg-red-400 rounded p-3 text-white  hover:bg-red-500"
                                            >
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>

                    
                </div>

                {/* Modal for editing company name */}
                {isModalOpen && (
                    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
                        <div className="flex items-center justify-center min-h-screen px-4">
                            <div className="bg-white p-6 rounded-md shadow-md w-full max-w-md">
                                <h3 className="text-lg font-medium mb-4">Edit Insurance Company</h3>
                                <input
                                    type="text"
                                    value={currentCompanyName}
                                    onChange={(e) => setCurrentCompanyName(e.target.value)}
                                    className="w-full p-2 border rounded-md mb-4"
                                />
                                <div className="flex justify-end">
                                    <button
                                        onClick={handleModalSave}
                                        className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                                    >
                                        Save
                                    </button>
                                    <button
                                        onClick={() => setIsModalOpen(false)}
                                        className="ml-2 px-4 py-2 bg-gray-300 rounded-md hover:bg-gray-400"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ConfigurationsPage;