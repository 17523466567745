import { useCallback, useState } from "react";
import { baseUrl } from "../CONSTANTS";
import { useData } from "../Context/DataContext";
import { useNavigate } from "react-router-dom";

export const useAppRequests = () => {
    const { setContextData } = useData();
    const navigate = useNavigate();

    const [loadingRequests, setLoadingRequests] = useState({});

    const withLoading = useCallback(async (key, asyncFunction) => {
        setLoadingRequests(prev => ({ ...prev, [key]: true }));
        try {
            const result = await asyncFunction();
            return result;
        } finally {
            setTimeout(() => {
                setLoadingRequests(prev => ({ ...prev, [key]: false }));
            }, 500); // Loading time in milliseconds...
        }
    }, []);

    const handleUnauthorized = useCallback(() => {
        setContextData({});
        localStorage.removeItem('token');
        localStorage.removeItem('contextData');
        navigate('/sign-in');
    }, [setContextData, navigate]);

    const unsecuredGet = useCallback((endpoint, loadingKey = endpoint) => 
        withLoading(loadingKey, async () => {
            let url = baseUrl + endpoint;
            const req = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
            });

            return await req.json();
        }), [withLoading]);

    const securedGet = useCallback((endpoint, loadingKey = endpoint) => 
        withLoading(loadingKey, async () => {
            const token = localStorage.getItem('token');
            let url = baseUrl + endpoint;
            const req = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
            const response = await req.json();
            if (req.status === 401) {
                handleUnauthorized();
            }
            return response;
        }), [withLoading, handleUnauthorized]);

    const unsecuredPost = useCallback((endpoint, body, loadingKey = endpoint) => 
        withLoading(loadingKey, async () => {
            let url = baseUrl + endpoint;
            const req = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(body)
            });
            return await req.json();
        }), [withLoading]);

    const securedPost = useCallback((endpoint, body, loadingKey = endpoint) => 
        withLoading(loadingKey, async () => {
            const token = localStorage.getItem('token');
            let url = baseUrl + endpoint;
            const req = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(body)
            });
            const response = await req.json();
            if (req.status === 401) {
                handleUnauthorized();
            }
            return response;
        }), [withLoading, handleUnauthorized]);

    const securedPut = useCallback((endpoint, body, loadingKey = endpoint) => 
        withLoading(loadingKey, async () => {
            const token = localStorage.getItem('token');
            let url = baseUrl + endpoint;
            const req = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(body)
            });
            const response = await req.json();
            if (req.status === 401) {
                handleUnauthorized();
            }
            return response;
        }), [withLoading, handleUnauthorized]);

    const securedDelete = useCallback((endpoint, loadingKey = endpoint) => 
        withLoading(loadingKey, async () => {
            const token = localStorage.getItem('token');
            let url = baseUrl + endpoint;
            const req = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const response = await req.json();
            if (req.status === 401) {
                handleUnauthorized();
            }
            return response;
        }), [withLoading, handleUnauthorized]);

    const isLoading = useCallback((key) => !!loadingRequests[key], [loadingRequests]);

    return {
        unsecuredGet,
        unsecuredPost,
        securedGet,
        securedPost,
        securedPut,
        securedDelete,
        isLoading
    };
};
// const securedPost = async (endpoint, body) => {
    //     const token = localStorage.getItem('token');
    //     try {
    //         let url = baseUrl + endpoint;
    //         const req = await fetch(url, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Accept': 'application/json',
    //                 'Authorization': `Bearer ${token}`
    //             },
    //             body: JSON.stringify(body)
    //         });

    //         const response = await req.json();
    //         if (req.status === 401) {
    //             setContextData({});
    //             localStorage.removeItem('token');
    //             localStorage.removeItem('contextData');
    //         }
    //         return response;
    //     } catch (error) {
    //         console.error("Fetch error:", error);
    //         throw error;
    //     }
    // };
    // const unsecuredPost = async (endpoint, body) => {
    //     try {
    //         let url = baseUrl + endpoint;
    //         const req = await fetch(url, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Accept': 'application/json'
    //             },
    //             body: JSON.stringify(body)
    //         });
    //         const response = await req.json();
    //         return response;
    //     } catch (error) {
    //         console.error("Fetch error:", error);
    //         throw error;
    //     }
    // };

    // Secured GET request
    // const securedGet = async (endpoint) =>withLoading(async ()=>{
    //     const token = localStorage.getItem('token');
    //     try {
    //         let url = baseUrl + endpoint;
    //         const req = await fetch(url, {
    //             method: 'GET',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Accept': 'application/json',
    //                 'Authorization': `Bearer ${token}`
    //             },
    //         });

    //         const response = await req.json();
    //         if (req.status === 401) {
    //             setContextData({});
    //             localStorage.removeItem('token');
    //             localStorage.removeItem('contextData');
    //         }
    //         return response;
    //     } catch (error) {
    //         console.error("Fetch error:", error);
    //         throw error;
    //     }
    // });
    //   const withLoading = async (asyncFunction) => {
//     setLoading(true);
//     try {
//       const result = await asyncFunction();
//       return result;
//     } finally {
//       setTimeout(() => setLoading(false), 500); // Minimum 500ms loading time
//     }
//   };

//     // Unsecured GET request
//     const unsecuredGet = async (endpoint) => {
//         try {
//             let url = baseUrl + endpoint;
//             const req = await fetch(url, {
//                 method: 'GET',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Accept': 'application/json'
//                 },
//             });
//             const response = await req.json();
//             return response;
//         } catch (error) {
//             console.error("Fetch error:", error);
//             throw error;
//         }
//     };