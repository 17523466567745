import React, { useEffect } from "react";
import { useData } from "../../Context/DataContext";
import { MdEventNote } from "react-icons/md";
import './style.css';

const ReservationBox = ({data={}, index})=>{
    
    return(
        <div className="">
            <div className="flex flex-col items-center  ">
              {/* <MdEventNote className="text-blue-500 text-3xl mb-2" /> */}
              
                  <div key={index} className="bg-white p-4 rounded-lg shadow-md mb-4 w-full max-w-md">
                  <p className="text-lg text-primary font-semibold">{data.name}</p>
                  <p className="text-sm text-base text-body-color light:text-light-6">{data.date} at {data.time}</p>
                </div>
              
            </div>
          </div>
    )

}

export default ReservationBox;