import React from "react";

const About = () => {
  return (
    <>
      <section className="overflow-hidden pt-20 pb-12 lg:pt-[120px] lg:pb-[90px] bg-light light:bg-light">
        <div className="container mx-auto">
          <div className="flex flex-wrap items-center justify-between -mx-4">
            <div className="w-full px-4 lg:w-6/12">
              <div className="flex items-center -mx-3 sm:-mx-4">
                {/* <div className="w-full px-3 sm:px-4 xl:w-1/2">
                  <div className="py-3 sm:py-4">
                    <img
                      src="https://i.ibb.co/gFb3ns6/image-1.jpg"
                      alt="Physical Therapy Session"
                      className="w-full rounded-2xl"
                    />
                  </div>
                  <div className="py-3 sm:py-4">
                    <img
                      src="https://i.ibb.co/rfHFq15/image-2.jpg"
                      alt="Therapist with Patient"
                      className="w-full rounded-2xl"
                    />
                  </div>
                </div> */}
                <div className="w-full px-3 sm:px-4 xl:w-2/3">
                  <div className="relative z-10 my-4">
                    <img
                      // src="https://i.ibb.co/9y7nYCD/image-3.jpg"
                      src="/Doctor.JPG"
                      alt="Therapy Equipment"
                      className="w-full rounded-2xl"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full px-4 lg:w-1/2 xl:w-5/12">
  <div className="mt-10 lg:mt-0">
    <span className="block mb-4 text-lg font-semibold text-primary">
      Dr. Ahmed's Path to Peak Recovery
    </span>
    <h2 className="mb-5 text-3xl font-bold text-primary sm:text-[40px]/[48px]">
      From Injury to Excellence
    </h2>
    <p className="mb-5 text-base text-body-color light:text-light-6">
      Dr. Ahmed is a dedicated physical therapist specializing in sports injuries and musculoskeletal conditions. He obtained his degree from Modern University in Egypt in 2019 and furthered his expertise by earning a Doctor of Physical Therapy (DPT) from Touro University in 2024.
    </p>
    <p className="mb-8 text-base text-body-color light:text-light-6">
      Known for his personalized and evidence-based approach, Dr. Ahmed combines the latest techniques with a genuine commitment to his patients. His goal is to help them recover, perform at their best, and maintain long-term health.
    </p>
    <p className="mb-8 text-base text-body-color light:text-light-6">
      <strong>Where you can find us?</strong>
      <p>10 Hanover Square, New York, NY 10005.</p>
      <a className="text-primary" target="_blank" href="https://maps.app.goo.gl/hC7JtPK9VLcXX3S56" style={{ textDecoration: 'none', fontWeight: 'bold' }}>See on Maps</a>
    </p>
    {/* <a
      href="/#"
      className="inline-flex items-center justify-center py-3 text-base font-medium text-center text-white border border-transparent rounded-md px-7 bg-primary hover:bg-opacity-90"
    >
      Learn More
    </a> */}
  </div>
</div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;