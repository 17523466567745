import React from 'react';
import { Sidebar } from "flowbite-react";
import { HiArrowSmRight, HiChartPie } from "react-icons/hi";
import { IoSettingsOutline, IoExit } from "react-icons/io5";
import { FaChartBar } from "react-icons/fa";
import "./style.css";
import { Link } from "react-router-dom";
import { useData } from '../../Context/DataContext';

export function AppSidebar({ activeTab }) {
  const {setContextData}= useData();
  return (
    <div className="h-full">
      <Sidebar aria-label="Default sidebar example" className="h-full sidebar-custom" style={{height:'68rem'}}>
        <Sidebar.Items>
          <Sidebar.ItemGroup className="flex flex-col h-full space-y-4"> {/* Increased space-y-2 to space-y-4 */}
            <div className="flex-grow space-y-4"> {/* Increased space-y-2 to space-y-4 */}
              <Link to='/admin/dashboard'>
                <Sidebar.Item 
                  className={`hover:bg-[#dbffdb] ${activeTab === 'dashboard' ? 'active-tab' : ''} mb-2`} 
                  icon={HiChartPie}
                >
                  Dashboard
                </Sidebar.Item>
              </Link>
              <Link to='/admin/configurations'>
                <Sidebar.Item 
                  className={`hover:bg-[#dbffdb] ${activeTab === 'configurations' ? 'active-tab' : ''} mb-2`} 
                  icon={IoSettingsOutline}
                >
                  System Configurations
                </Sidebar.Item>
              </Link>
              <Link to='/admin/charts'>
                <Sidebar.Item 
                  className={`hover:bg-[#dbffdb] ${activeTab === 'charts' ? 'active-tab' : ''} mb-2`} 
                  icon={FaChartBar}
                >
                  Charts
                </Sidebar.Item>
              </Link>
              <Link to='/'>
                <Sidebar.Item className="hover:bg-[#dbffdb] mb-2" icon={HiArrowSmRight}>
                  Go to Home page
                </Sidebar.Item>
              </Link>
            </div>
            <div className="mt-auto">
              <Link to={'/'} onClick={()=>{
                setContextData({});
                localStorage.removeItem('token');
                localStorage.removeItem('contextData');
              }}>
              <Sidebar.Item  icon={IoExit} className="hover:bg-[#dbffdb]">
                  Logout
                {/* <button onClick={} >
                </button> */}
              </Sidebar.Item>
              </Link>
            </div>
          </Sidebar.ItemGroup>
        </Sidebar.Items>
      </Sidebar>
    </div>
  );
}