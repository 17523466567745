import React, { createContext, useState, useContext, useEffect } from 'react';
import initialContext from './initial-context.json' ;
const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [contextData, setContextData] = useState(() => {
    const savedData = localStorage.getItem('contextData');
    return savedData ? JSON.parse(savedData) : initialContext;
  });

  useEffect(() => {
    localStorage.setItem('contextData', JSON.stringify(contextData));
  }, [contextData]);

  return (
    <DataContext.Provider value={{ contextData, setContextData }}>
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => useContext(DataContext);
