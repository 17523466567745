import React, { useEffect, useState } from "react";
import { AppSidebar } from "../../../components/App-Sidebar/AppSidebar";
import { PieChart, Pie, Cell, BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useAppRequests } from "../../../Network/Requests";
import AppLoader from "../../../components/App-Loader/AppLoader";

const ChartsPage = () => {
    const { securedGet, isLoading } = useAppRequests();
    const [charts, setCharts] = useState(null);

    useEffect(() => {
        // Fetch the chart data from the API
        const fetchChartsData = async () => {
            try {
                // console.log("Fetching charts data...");
                // console.log("API Response:", response);
                const response = await securedGet('/charts', 'charts');
                if (response.success) {
                    setCharts(response.response.data);
                } else {
                    console.error("Failed to load charts data");
                }
            } catch (error) {
                console.error("Error fetching charts data", error);
            }
        };
    
        fetchChartsData();
    }, []);

    // Colors for the pie chart
    const COLORS = ['#22C55E','#36A2EB', '#FF6384'];

    // Prepare data for charts if available
    const pieData = charts ? [
        { name: 'Completed', value: charts.reservationsStatus.completed },
        { name: 'Confirmed', value: charts.reservationsStatus.confirmed },
        { name: 'Canceled', value: charts.reservationsStatus.cancelled },
    ] : [];

    const barData = charts ? charts.topUsedInsuranceCompanies.map(company => ({
        name: company.name,
        count: company.count,
    })) : [];

    const lineData = charts ? Object.keys(charts.userRegesteration).map(date => ({
        date: date,
        count: charts.userRegesteration[date],
    })) : [];

    // Custom label for Pie chart
    const renderCustomLabel = ({ name, percent }) => ` ${(percent * 100).toFixed(0)}%`;

    return (
        <div className="flex h-screen">
            <AppSidebar activeTab={'charts'} />
            <div className="flex flex-col w-full p-8">
                <h1 className="text-2xl font-bold mb-8">Charts Page</h1>
                {isLoading('charts')  ? (
                    <AppLoader  />
                ) : (
                    <>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                            <div className="chart-container">
                                <h2 className="text-xl font-semibold mb-4">Reservations Status</h2>
                                <PieChart width={400} height={400}>
                                    <Pie
                                    
                                        data={pieData}
                                        cx={200}
                                        cy={200}
                                        labelLine={false}
                                        label={renderCustomLabel}
                                        outerRadius={150}
                                        fill="#8884d8"
                                        dataKey="value"
                                    >
                                        {pieData.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip />
                                </PieChart>
                            </div>
                            <div className="chart-container mt-8">
                                <h2 className="text-xl font-semibold mb-4">Top Used Insurance Companies</h2>
                                <ResponsiveContainer width={400} height={400}>
                                    <BarChart data={barData}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="count" fill="#36A2EB" />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                        <div className="chart-container mt-8">
                            <h2 className="text-xl font-semibold mb-4">User Registration by Time</h2>
                            <div className="flex items-center justify-center">
                                
                            <LineChart width={600} height={300} data={lineData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="count" stroke="#FF6384" />
                            </LineChart>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default ChartsPage;