import React, {  useState } from "react";
import AppModal from "../App-Modal/AppModal";

const AppTable = ({ columns, data, scheduleClick, cancelClick, timeOptions, adminTable = false, markCompleted, markCancelled }) => {
  const [isRescheduleModalOpen, setIsRescheduleModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isOptionsModalOpen, setIsOptionsModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [newDate, setNewDate] = useState("");
  const [newTime, setNewTime] = useState("");

  const isActionDisabled = (status, date) => {
    const isPastDate = new Date(date) < new Date();
    return status === 'cancelled' || status === 'completed' || isPastDate;
  };
  const getStartingDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate() + 2).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'confirmed':
        return 'bg-blue-500 text-white';
      case 'completed':
        return 'bg-green-500 text-white';
      case 'cancelled':
        return 'bg-red-500 text-white';
      default:
        return 'bg-gray-200 text-gray-700';
    }
  };

  const handleReschedule = (row) => {
    setSelectedRow(row);
    setNewDate(row.date.split('T')[0]);
    setNewTime(row.time);
    setIsRescheduleModalOpen(true);
  };

  const handleOptions = (row) => {
    // IDK why but for some reason when I use setSelectedRow it craches.
    // No time for investigation now so I'll leave this as it is for now.
    console.log(row);

    setCurrentRow(row);
    setIsOptionsModalOpen(true);
  };

  const handleCancel = (row) => {
    console.log(row);
    setSelectedRow(row);
    setIsCancelModalOpen(true);
  };

  const confirmReschedule = () => {
    scheduleClick({ ...selectedRow, newDate, newTime });
    setIsRescheduleModalOpen(false);
  };

  const confirmCancel = () => {
    cancelClick(selectedRow);
    setIsCancelModalOpen(false);
  };

  const handleMarkCompleted = () => {
    markCompleted(currentRow);
  };
  const handleMarkCancelled = () => {
    markCancelled(currentRow);
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            {columns.map((column) => (
              <th
                key={column.accessor}
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                {column}
              </th>
            ))}
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {data.map((row, index) => (
            <tr key={index}>
              {columns.map((column) => (
                <td
                  key={column.accessor}
                  className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                >
                  {column === 'status' ? (
                    <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded ${getStatusColor(row[column])}`}>
                      {row[column]}
                    </span>
                  ) : (
                    row[column]
                  )}
                </td>
              ))}
              {adminTable ? (
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  <button
                    type="button"
                    className="mr-4 text-blue hover:text-blue-900"
                    onClick={() => handleOptions(row)}
                  >
                    Options
                  </button>
                </td>
              ) : (
                <>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <button
                      type="button"
                      className={`mr-4 ${isActionDisabled(row.status, row.date)
                        ? 'text-gray-400 cursor-not-allowed'
                        : 'text-blue-600 hover:text-blue-900'
                        }`}
                      onClick={() => !isActionDisabled(row.status, row.date) && handleReschedule(row)}
                      disabled={isActionDisabled(row.status, row.date)}
                    >
                      Reschedule
                    </button>
                    <button
                      type="button"
                      className={`${isActionDisabled(row.status, row.date)
                        ? 'text-gray-400 cursor-not-allowed'
                        : 'text-red-600 hover:text-red-900'
                        }`}
                      onClick={() => !isActionDisabled(row.status, row.date) && handleCancel(row)}
                      disabled={isActionDisabled(row.status, row.date)}
                    >
                      Cancel
                    </button>
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>

      {/* Reschedule Modal */}
      <AppModal
        isOpen={isRescheduleModalOpen}
        onClose={() => setIsRescheduleModalOpen(false)}
        title="Reschedule Reservation"
        footer={
          <>
            <button
              onClick={() => setIsRescheduleModalOpen(false)}
              className="px-4 py-2 bg-gray-200 text-gray-800 rounded mr-2"
            >
              Cancel
            </button>
            <button
              onClick={confirmReschedule}
              className="px-4 py-2 bg-blue-500 text-white rounded"
            >
              Confirm Reschedule
            </button>
          </>
        }
      >
        <p>Select a new date and time for your reservation:</p>
        {selectedRow && (
          <div className="mt-4">
            <p>Current Reservation: <span className="text-blue-500">{selectedRow.service}</span></p>
            <div className="mt-2">
              <label className="block text-sm font-medium text-gray-700">New Date:</label>
              <input
                type="date"
                value={newDate}
                min={getStartingDate()}
                onChange={(e) => setNewDate(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
            <div className="mt-2">
              <label className="block text-sm font-medium text-gray-700">New Time:</label>
              <select
                value={newTime}
                onChange={(e) => setNewTime(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              >
                {timeOptions.map((time) => (
                  <option key={time} value={time}>
                    {time}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
      </AppModal>

      {/* Cancel Modal */}
      <AppModal
        isOpen={isCancelModalOpen}
        onClose={() => setIsCancelModalOpen(false)}
        title="Cancel Reservation"
        footer={
          <>
            <button
              onClick={() => setIsCancelModalOpen(false)}
              className="px-4 py-2 bg-gray-200 text-gray-800 rounded mr-2"
            >
              No, Keep It
            </button>
            <button
              onClick={confirmCancel}
              className="px-4 py-2 bg-red-500 text-white rounded"
            >
              Yes, Cancel
            </button>
          </>
        }
      >
        <p>Are you sure you want to cancel this reservation?</p>
        {selectedRow && (
          <p>
            Reservation: {selectedRow.title} on {selectedRow.date} at{" "}
            {selectedRow.time}
          </p>
        )}
      </AppModal>

      {/* Options Modal */}
      <AppModal
        isOpen={isOptionsModalOpen}
        onClose={() => setIsOptionsModalOpen(false)}
        title="Reservation Details"

      >
  {currentRow && (
  <div className="p-4 space-y-4">
    <div className="flex space-x-4">
      <div className="bg-gray-100 p-4 rounded-lg shadow-md flex-1">
        <p className="text-md font-semibold text-primary"><span className="text-gray-500">Service:</span> {currentRow.service}</p>
      </div>
      <div className="bg-gray-100 p-4 rounded-md shadow-md flex-1">
        <p className="text-md font-semibold text-primary"><span className="text-gray-500">Date:</span> {currentRow.date}</p>
        <p className="text-md font-semibold text-primary"><span className="text-gray-500">Time:</span> {currentRow.time}</p>
      </div>
    </div>
    
    <div className="bg-gray-50 p-4 rounded-lg shadow-md">
      <p className="text-lg font-semibold text-teal-600"><span className="text-gray-500">Patient:</span> {currentRow.patient}</p>
      <p className="text-gray-600"><span className="font-medium text-gray-700">Email:</span> {currentRow.patientInfo.email}</p>
      <p className="text-gray-600"><span className="font-medium text-gray-700">Phone:</span> {currentRow.patientInfo.phone}</p>
    </div>
    
    <div className="flex items-center space-x-2">
      <p className="text-lg font-semibold text-gray-700"><span className="text-gray-500">Status:</span></p>
      <span className={`px-3 py-1 inline-flex text-sm leading-5 font-semibold rounded-full ${getStatusColor(currentRow.status)}`}>
        {currentRow.status}
      </span>
    </div>

    {currentRow.status.toLowerCase() === 'confirmed' && (
      <div className="flex mt-6 space-x-4">
        <button
          onClick={handleMarkCompleted}
          className="px-6 py-2 bg-green-500 text-white rounded-lg shadow hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500"
        >
          Mark as Completed
        </button>
        <button
          onClick={handleMarkCancelled}
          className="px-6 py-2 bg-red-500 text-white rounded-lg shadow hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
        >
          Mark as Cancelled
        </button>
      </div>
    )}
  </div>
)}
      </AppModal>
    </div>
  );
};

export default AppTable;