import React from "react";
import { useData } from "../../Context/DataContext";

const UserAvatar = ({style}) => {
    const {contextData , setContextData} = useData();
    const username = contextData.user.name; 
    const image = `https://ui-avatars.com/api/?name=${username}&size=40`;
    return (
        <div style={{marginTop:'20px'}}>
            <img src={image} alt={username} className="rounded-full h-10 w-10" />
            {/* <span className="text-xs text-gray-500">{username}</span> */}
        </div>

    );

}

export default UserAvatar;