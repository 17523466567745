import React, { useEffect, useState } from "react";
import AppNavbar from "../../components/App-Navbar/AppNavbar";
import { useData } from "../../Context/DataContext";
import './style.css'
import AppTable from "../../components/App-Table/AppTable";
import CalendarView from "../../components/CalendarView/CalendarView";
import { useAppRequests } from "../../Network/Requests";
import AppLoader from "../../components/App-Loader/AppLoader";

const ReservationPage = () => {
    const { contextData } = useData();
    const [reservationData, setReservationData] = useState([]);
    const [timeOptions, setTimeOptions] = useState([]);
    const [reservationMessage, setReservationMessage] = useState({
        'message': '',
        'type': 1 // 1 for success, 0 for error
    });
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalPages: 1
    });
    const { securedGet,securedDelete, unsecuredGet, securedPut , isLoading } = useAppRequests();

    

  useEffect(() => {
    const fetchTimeOptions = async () => {
      try {
        const response = await unsecuredGet('/working-hours');
        const workingHours = response.response.data;
        
        let times = [];
        for (let hour = parseInt(workingHours.start); hour <= parseInt(workingHours.end); hour++) {
          const time = `${hour.toString().padStart(2, '0')}:00`;
          times.push(time);
        }
        setTimeOptions(times);
      } catch (error) {
        console.error('Error fetching working hours:', error);
        // Fallback to default times if fetch fails
        setTimeOptions(['09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00']);
      }
    };

    fetchTimeOptions();
  }, []);


    const fetchReservations = async (page = 1) => {
        const response = await securedGet(`/reservations?page=${page}`, 'reservations');
        if (response.success) {
            const formattedData = response.response.data.data.map(reservation => ({
                id: reservation.id,
                service: reservation.service.name,
                title: reservation.service.name,
                date: `${reservation.date}T${reservation.time}`,
                time: reservation.time.slice(0, 5),
                status: reservation.status,
                color: getStatusColor(reservation.status)
            }));
            setReservationData(formattedData);
            setPagination({
                currentPage: response.response.data.current_page,
                totalPages: response.response.data.last_page
            });
        } else {
            console.log(response);
        }
    }
    useEffect(() => {
        fetchReservations();
    }, [securedGet]);

    const handleCancelClick = async(e) => {
        const response = await securedDelete(`/cancel-reservation?reservationId=${e.id}` , 'reservations');
        if (response.success) {
            setReservationMessage({ message: response.response.message, type: 1 });
            fetchReservations();
        }
        else {
            setReservationMessage({ message: response.response.error, type: 0 });
            console.log(response);
        }
        
        
    }
    const handleScheduleClick = async(e) => {
        console.log('scheduling : ' , e);
        const data = {
            reservationId: e.id,
            date: e.newDate ? e.newDate : e.date,
            time: e.newTime ? e.newTime : e.time
        };
        console.log(data);
        
        const response = await securedPut('/reschedule-reservation', data, 'reservations' );
        // console.log(response);
        
        if (response.success) {
            setReservationMessage({ message: response.response.message, type: 1 });
            fetchReservations();
        }
        else {
            setReservationMessage({ message: response.response.error, type: 0 });
            console.log(response);
        }
        
    }
    

    const [viewMode, setViewMode] = useState(1) // 1 Table View, 2 Calendar View

    const handleViewButton = (int) => {
        setViewMode(int);
    }

    function getStatusColor(status) {
        switch (status) {
            case 'cancelled': return 'rgb(239 68 68)';
            case 'completed': return 'rgb(34 197 94)';
            case 'confirmed': return 'rgb(59 130 246)';
            default: return 'gray';
        }
    }

    const handlePreviousPage = () => {
        if (pagination.currentPage > 1) {
            fetchReservations(pagination.currentPage - 1);
        }
    }

    const handleNextPage = () => {
        if (pagination.currentPage < pagination.totalPages) {
            fetchReservations(pagination.currentPage + 1);
        }
    }

    return (
        <>
            <div className="reservationsContainer pb-44">
                <div className="container">
                    <div className="flex items-center m-5 justify-center">
                        <button
                            onClick={() => handleViewButton(1)}
                            className={`
                                ${viewMode === 1 ? 'bg-green-800 text-white border-transparent' : 'bg-transparent text-green-700 border-green-500'}
                                m-2 font-semibold py-2 px-4 border rounded hover:bg-green-800 hover:text-white hover:border-transparent
                            `}
                        >
                            Table View
                        </button>
                        <button
                            onClick={() => handleViewButton(2)}
                            className={`
                                ${viewMode === 2 ? 'bg-green-800 text-white border-transparent' : 'bg-transparent text-green-700 border-green-500'}
                                m-2 font-semibold py-2 px-4 border rounded hover:bg-green-800 hover:text-white hover:border-transparent
                            `}
                        >
                            Calendar View
                        </button>
                    </div>
                    <h1 className="text-center m-5">Your Reservations</h1>
                    <>
                    
                        {isLoading('reservations') ? (
                            <>
                            
                            <AppLoader />
                                </>
                        ) : (
                            reservationData.length > 0 ? (
                                <>
                                
                                <div
                                className={`text-sm text-center ${reservationMessage.type === 1 ? 'text-green-500' : 'text-red-500'
                                }`}
                                >
                                {reservationMessage.message}
                            </div>
                            
                                    {viewMode === 1 ? (
                                        <AppTable
                                        scheduleClick={handleScheduleClick}
                                        cancelClick={handleCancelClick} 
                                        columns={['service', 'date', 'time', 'status']} 
                                        timeOptions={timeOptions}
                                        data={reservationData} />
                                    ) : (
                                        <div>
                                            <div className="flex justify-center mb-5">
                                                <div className="flex items-center mr-4">
                                                    <div className="w-4 h-4 bg-red-500 mr-2"></div>
                                                    <span>Cancelled</span>
                                                </div>
                                                <div className="flex items-center mr-4">
                                                    <div className="w-4 h-4 bg-green-500 mr-2"></div>
                                                    <span>Completed</span>
                                                </div>
                                                <div className="flex items-center mr-4">
                                                    <div className="w-4 h-4 bg-blue-500 mr-2"></div>
                                                    <span>Confirmed</span>
                                                </div>
                                            </div>
                                            <CalendarView events={reservationData} />
                                        </div>
                                    )}
                                    <div className="flex justify-center mt-5">
                                        <button 
                                            onClick={handlePreviousPage} 
                                            disabled={pagination.currentPage === 1}
                                            className="mx-2 px-4 py-2 bg-green-500 text-white rounded disabled:bg-gray-300"
                                        >
                                            &#8592; Previous
                                        </button>
                                        <span className="mx-2 py-2">
                                            Page {pagination.currentPage} of {pagination.totalPages}
                                        </span>
                                        <button 
                                            onClick={handleNextPage} 
                                            disabled={pagination.currentPage === pagination.totalPages}
                                            className="mx-2 px-4 py-2 bg-green-500 text-white rounded disabled:bg-gray-300"
                                        >
                                            Next &#8594;
                                        </button>
                                    </div>
                                </>
                            ) : (
                                <div className="flex flex-col items-center">
                                    <p className="text-lg font-normal text-center m-5">No upcoming reservations</p>
                                </div>
                            )
                        )}
                    </>
                </div>
            </div>
        </>
    );
};

export default ReservationPage;