import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import AppNavbar from './components/App-Navbar/AppNavbar';
import { DataProvider } from './Context/DataContext';
import SignupPage from './pages/Signup/Signup';
import SignInPage from './pages/Signin/Signin';
import BookingPage from './pages/Booking/Booking';
import DashboardPage from './pages/Admin/Dashboard/Dashboard';
import ReseravtionPage from './pages/Reservation/Reservation';
import ConfigurationsPage from './pages/Admin/Configurations/Configurations';
import ChartsPage from './pages/Admin/Charts/Charts';
import ForgetPasswordPage from './pages/Forgot-pwd/ForgotPassword';
import HomePage from './pages/Home/Home';
import AppFooter from './components/App-Footer/AppFooter';

function AdminRoutes() {
  return (
    <Routes>
      <Route path="/dashboard" element={<DashboardPage />} />
      <Route path="/configurations" element={<ConfigurationsPage />} />
      <Route path="/charts" element={<ChartsPage />} />
    </Routes>
  );
}

function UserRoutes() {
  return (
    <>
      <div className="main-content">
        <AppNavbar />
        <div className=''>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/sign-in" element={<SignInPage />} />
            <Route path="/sign-up" element={<SignupPage />} />
            <Route path='/forget-password' element={<ForgetPasswordPage />} />
            <Route path="/booking" element={<BookingPage />} />
            <Route path="/reservations" element={<ReseravtionPage />} />
          </Routes>
        </div>
        
      </div>
    </>
  );
}

function App() {
  return (
    <DataProvider>
      <Router>
        <Routes>
          <Route path="/admin/*" element={<AdminRoutes />} />
          <Route path="/*" element={<UserRoutes />} />
        </Routes>
      </Router>
    </DataProvider>
  );
}

export default App;