import React, { useEffect, useState } from "react";
import { useData } from "../../Context/DataContext";
import './style.css'
import ReservationBox from "../Reservation-Box/ReservationBox";
import { Link } from "react-router-dom";
import { useAppRequests } from "../../Network/Requests";
import { LineWave } from "react-loader-spinner";
import AppLoader from "../App-Loader/AppLoader";
// import UpcomingRservations from "../Upcoming-Reservation/UpcomingReservation";

const Hero = () => {
  const { securedGet, isLoading } = useAppRequests();
  const { contextData } = useData();
  const [upcomingRservations, setUpcomingRservations] = useState([]);
  // const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchUpcomingRservations = async () => {
      // setLoading(true);
      const response = await securedGet('/upcoming-reservations', 'upcomingReservations');
      // if(!response.success)
      //   return setLoading(false);
      
      if (response.success) {
        if (response.response.data.length == 0)
          return setUpcomingRservations([]);
        //   setLoading(false);
        //   return setLoading(false) ;
        const reservation = response.response.data[0];
        const resObj = {
          'name': reservation.service.name,
          'date': reservation.date,
          'time': reservation.time,
        }
        setUpcomingRservations([resObj]);
        // setLoading(false);
      }
      
    }
    if(contextData.loggedIn)
    fetchUpcomingRservations();
  }, [])
  return (
    <>
      <div className="relative  pb-[110px] pt-[120px] hero-container  lg:pt-[150px] ">
        <div className="container">
          <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4 lg:w-5/12">
              <div className="hero-content">
                <h1 className="mb-5 text-4xl font-bold !leading-[1.208] text-primary light:text-white sm:text-[42px] lg:text-[40px] xl:text-5xl">
                  Welcome to Physical Therapy of New York!
                </h1>
                <p className="mb-8 max-w-[480px] text-base text-body-color light:text-light-6">
                  At Physical Therapy of New York, we provide comprehensive physical therapy services to help you recover and thrive. Our experienced therapists use the latest techniques to ensure you get the best care possible.
                </p>
                <div className="relative z-10 inline-block pt-11 lg:hidden md:hidden rounded">
                  <img
                    // height={'300px'}
                    // width={'350rem'}
                    // src="https://img.freepik.com/free-photo/cheerful-young-doctor-with-tablet-hall_23-2147763865.jpg?t=st=1722433853~exp=1722437453~hmac=3383ca53382b6f7e56d5f51fe6a26ebeed6db7ac614af131757994d7fcc75fab&w=1800"
                    src="/Hero-png.png"
                    alt="Physical Therapy"
                    className="max-w-full lg:ml-auto rounded-xl"
                  />
                </div>
                <ul className="flex flex-wrap items-center">
                  <li>
                    <Link
                      to="/booking"

                      className="inline-flex my-2 mr-5 items-center justify-center rounded-md bg-primary px-6 py-3 text-center text-base font-medium text-white hover:bg-green-dark lg:px-7 "
                    >
                      Book an Appointment
                    </Link>
                  </li>
                  <li>
                    {contextData.loggedIn && (<Link
                      to="/reservations"
                      className="inline-flex items-center justify-center rounded-md  px-6 py-3 text-center text-base font-medium text-primary  border border-emerald-600 lg:px-7"
                    >
                      View Reseravtions
                    </Link>)}

                  </li>

                </ul>
                {contextData.loggedIn && (
                  <>
                    {isLoading('upcomingReservations') ? (
                      <AppLoader />
                    ) : (
                      upcomingRservations.length > 0 ? (
                        <>
                          <p className="font-normal text-center m-5 text-blue-900">Upcoming Reservations</p>
                          {upcomingRservations.map((reservation, index) => (
                            <div key={index}>
                              <ReservationBox data={reservation} index={index} />
                            </div>
                          ))}
                        </>
                      ) : (
                        <div className="flex flex-col items-center">
                          <p className="text-lg font-normal text-center m-5">No upcoming reservations</p>
                        </div>
                      )
                    )}
                  </>
                )}
                {/* <UpcomingRservations /> */}
                {/* <div className="clients pt-16">
                  <h6 className="mb-6 flex items-center text-xs font-normal text-body-color light:text-light-6">
                    Trusted By
                    <span className="ml-3 inline-block h-px w-8 bg-body-color"></span>
                  </h6>
                  <div className="flex items-center space-x-4">
                    <SingleImage
                      href="#"
                      imgSrc="https://cdn.tailgrids.com/2.0/image/assets/images/brands/ayroui.svg"
                    />
                    <SingleImage
                      href="#"
                      imgSrc="https://cdn.tailgrids.com/2.0/image/assets/images/brands/graygrids.svg"
                    />
                    <SingleImage
                      href="#"
                      imgSrc="https://cdn.tailgrids.com/2.0/image/assets/images/brands/uideck.svg"
                    />
                  </div>
                </div> */}
              </div>
            </div>
            <div className="hidden px-4 lg:block lg:w-1/12"></div>
            <div className="w-full px-4 lg:w-6/12">
              <div className="lg:ml-auto lg:text-right">
                <div className="relative z-10 hidden lg:flex md:flex inline-block pt-11 lg:pt-0  rounded">
                  <img
                    // height={'300px'}
                    // width={'350rem'}
                    // src="https://img.freepik.com/free-photo/cheerful-young-doctor-with-tablet-hall_23-2147763865.jpg?t=st=1722433853~exp=1722437453~hmac=3383ca53382b6f7e56d5f51fe6a26ebeed6db7ac614af131757994d7fcc75fab&w=1800"
                    src="/Hero-png.png"
                    alt="Physical Therapy"
                    className="max-w-full lg:ml-auto rounded-xl"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;

const SingleImage = ({ href, imgSrc }) => {
  return (
    <a href={href} className="flex w-full items-center justify-center">
      <img src={imgSrc} alt="brand image" className="h-10 w-full" />
    </a>
  );
};