import React, { useEffect, useState } from "react";
import { FaRegUserCircle } from "react-icons/fa";
import Joi, { log } from "joi-browser";
import { useAppRequests } from "../../Network/Requests";
import { LineWave } from "react-loader-spinner";
import { useData } from "../../Context/DataContext";
import { Link, redirect, useNavigate } from "react-router-dom";
import AppLoader from "../../components/App-Loader/AppLoader";
const SignInPage = () => {
    const navigate = useNavigate();
    const { unsecuredPost, unsecuredGet } = useAppRequests();
    const { contextData, setContextData } = useData();
    // useEffect(() => {
    //     console.log(contextData);

    // }, [contextData])
    const [data, setData] = useState({
        email: '',
        password: ''
    });
    const [loading, setLoading] = useState(false);

    const [errors, setErrors] = useState({});
    const [submissionMessage, setSubmissionMessage] = useState({
        message: '',
        type: 1 // 1 for success, 0 for error
    });

    const saveUserData = async (token, user) => {
        localStorage.setItem('token', token);

        setContextData((prevState) => ({
            ...prevState,
            loggedIn: true,
            user: {
                name: `${user.first_name} ${user.last_name}`,
                email: user.email,
                phone: user.phone,
                firstName: user.first_name,
                lastName: user.last_name,
                role: user.role,
                token: token
            }
        }));
    };


    const validateForm = () => {
        // e.preventDefault();
        const validationResult = Joi.validate(data, schema, { abortEarly: false });

        if (validationResult.error) {
            const errorMessages = {};
            validationResult.error.details.forEach(detail => {
                errorMessages[detail.path[0]] = detail.message;
            });
            setErrors(errorMessages);
            // console.log(errorMessages); // For debugging
            return { success: false, errorMessages }
        }
        setErrors({})
        return { success: true }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log(data);
        const validateData = validateForm();
        // console.log(validateData);
        if (validateData.success) {
            setLoading(true);
            const response = await unsecuredPost('/login', data);

            if (!response.success) {
                setSubmissionMessage({ message: response.response.error, type: 0 });
                setLoading(false);
            }
            else {

                // console.log(response);
                setSubmissionMessage({ message: response.response.message, type: 1 });
                saveUserData(response.response.token, response.response.user);
                // console.log(response.response.user);

                setLoading(false);
                if (response.response.user.role == 'admin')
                    navigate('/admin/dashboard');
                else if (response.response.user.role == 'user')
                    navigate('/');
            }



        }


        // console.log('Form submitted');
    }
    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const schema = {
        email: Joi.string().email().label('Email').required(),
        password: Joi.string().min(8).label('Password').required()
    }
    return (
        <>

            <div className="flex min-h-full mt-20 flex-1 flex-col justify-center px-6 py-12 lg:px-8 ">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm text-center">
                    {/* <FaRegUserCircle  /> */}
                    <div className="flex justify-center">
                        <FaRegUserCircle className="text-6xl text-primary " />
                    </div>
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Sign in to your account
                    </h2>
                </div>

                {loading ? (
                    <AppLoader />
                ) : (
                    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                        {submissionMessage.message && (
                            <div
                                className={`text-sm text-center ${submissionMessage.type === 1 ? 'text-green-500' : 'text-red-500'
                                    }`}
                            >
                                {submissionMessage.message}
                            </div>
                        )}

                        <div className="my-2">
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                Email address
                            </label>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    required
                                    autoComplete="email"
                                    value={data.email}
                                    onChange={(e) => { handleChange(e) }}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            {errors.email && <div className="text-red-500 text-sm">{errors.email}</div>}
                        </div>

                        <div className="my-2">
                            <div className="flex items-center justify-between">
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                    Password
                                </label>
                                <div className="text-sm">
                                    <Link to={'/forget-password'} className="font-semibold text-primary hover:text-primary-500">
                                        Forgot password?
                                    </Link>
                                </div>
                            </div>
                            <div className="mt-2">
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    value={data.password}
                                    required
                                    onChange={(e) => { handleChange(e) }}
                                    autoComplete="current-password"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                            {errors.password && <div className="text-red-500 text-sm">{errors.password}</div>}
                        </div>

                        <div className="mt-5">
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 my-5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
                                onClick={handleSubmit}
                            >
                                Sign in
                            </button>
                        </div>
                        {/* </form> */}

                        <p className="mt-10 text-center text-sm text-gray-500">
                            Not a member?{' '}
                            <Link to="/sign-up" className="font-semibold leading-6 text-primary hover:text-primary-500">
                                Create a new account
                            </Link>
                        </p>
                    </div>
                )}
            </div>
        </>
    )
}

export default SignInPage;