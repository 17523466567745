import React, { useEffect, useState } from "react";
import { FaCalendarAlt, FaNutritionix } from "react-icons/fa";
import { GiBodyBalance, GiHealing, GiHealthCapsule, GiLeg } from "react-icons/gi";
import { RiPsychotherapyFill } from "react-icons/ri";
import { useAppRequests } from "../../Network/Requests";
import { TbPhysotherapist, TbStretching } from "react-icons/tb";

import AppLoader from "../App-Loader/AppLoader";
import './style.css'
import { FaWaveSquare, FaHandsHelping, FaSocks, FaPumpMedical, FaWalking, FaBalanceScale, FaRunning, FaBolt } from 'react-icons/fa';

const Services = () => {
  const {unsecuredGet , isLoading}= useAppRequests();
  // const [services , setServices] = useState([]);
const services =[
  {
    id: 1,
    title: "Shockwave Therapy for Joint Pain",
    content: "Experience the power of developed shockwave technology to effectively target and relieve chronic joint pain, allowing you to achieve greater mobility and peak performance.",
    icon: <FaWaveSquare />
  },
  {
    id: 2,
    title: "Graston Technique and Soft Tissue Scraping",
    content: "Partake the benefits of the Graston technique combined with soft tissue scraping to address muscle and connective tissue issues, promoting faster recovery and improved function.",
    icon: <FaHandsHelping />
  },
  {
    id: 3,
    title: "Compression Therapy",
    content: "Our compression therapy sessions will reduce swelling, improve circulation, and boost physical performance for faster recovery and enhanced results.",
    icon:<TbPhysotherapist  size={25}/>
  },
  {
    id: 4,
    title: "Dry Cupping and Myofascial Release",
    content: "We provide dry cupping and myofascial release techniques to alleviate muscle tension, enhance blood circulation, and speed ​up The healing process.",
    icon: <GiHealing size={25} />
  },
  {
    id: 5,
    title: "Soft Tissue and Joint Mobilization",
    content: "Our skilled therapists employ precise soft tissue and joint mobilization techniques to elevate flexibility, alleviate pain, and reestablish optimal movement.",
    icon: <GiLeg size={25} />
    // icon : <img src="/icons/joint-mobilization.png" />
  },
  {
    id: 6,
    title: "Balance and Stretch Training",
    content: "Transform your stability and flexibility with our personalized balance and stretch training programs, meticulously crafted to elevate your physical prowess.",
    icon: <GiBodyBalance size={25} />
  },
  {
    id: 7,
    title: "Sports Injury Rehabilitation",
    content: "The specialized programs we offer for sports injury recovery are customized to suit your requirements, whether you're recuperating from a recent injury or managing a long-term condition. We aim to assist you in getting back to physical activities.",
    icon: <FaRunning size={25} />
  },
  {
    id: 8,
    title: "Speed and Agility Training Exercise",
    content: "Our specialized speed and agility training exercises rest assured, it is guaranteed. enhance your athletic performance by improving your reaction time and physical speed.",
    icon: <FaBolt />
  }
];
  // useEffect(()=>{
  //   const fetchServices = async()=>{
  //     const response = await unsecuredGet('/clinic-services', 'services');
  //     if(response.success)
  //       setServices(response.response.data);
  //   }
  //   fetchServices();
  // }, []);
  return (
    <section className="pb-12 pt-20   dark:bg-dark lg:pb-[90px] lg:pt-[120px]">
      <div className="container mx-auto">
        <div className="-mx-4 flex flex-wrap  ">
          <div className="w-full px-4">
            <div className="mx-auto mb-12 max-w-[510px] text-center lg:mb-20">
              <span className="mb-2 block text-lg font-semibold text-primary">
                Our Services
              </span>
              <h2 className="mb-3 text-3xl font-bold leading-[1.2] text-dark dark:text-white sm:text-4xl md:text-[40px]">
                What We Offer
              </h2>
              <p className="text-base text-body-color dark:text-dark-6">
              Discover our available services designed to meet your needs and exceed your expectations.
 
              </p>
            </div>
          </div>
        </div>

        <div className="-mx-4 flex flex-wrap justify-center">
          {isLoading('services') ? (
            <AppLoader textColor="white" />
          ):(
            <> 
            {services.map(service =>(
              <ServiceCard
              title={service.title}
              details={service.content}
              icon={
                service.icon
              }
              />
            ))}
             {/* <ServiceCard
            title="Physical Therapy"
            details="We provide personalized physical therapy to help you recover from injuries and improve your physical health."
            icon={
              <RiPsychotherapyFill size={35} />
            }
            /> */}
            {/*
            <ServiceCard
            title="Nutritional Counseling"
            details="Our experts provide nutritional counseling to help you maintain a balanced diet and healthy lifestyle."
            icon={
              <FaNutritionix size={35} />
            }
            />
            <ServiceCard
            title="Rehabilitation Programs"
            details="Comprehensive rehabilitation programs designed to restore function and mobility after an injury."
            icon={
              <FaCalendarAlt size={30} />
            }
            />
            <ServiceCard
            title="Health Assessments"
            details="Regular health assessments to monitor your health and prevent potential health issues."
            icon={
              <GiHealthCapsule size={35} />
            }
            /> */}
            </>
          )}
        </div>
      </div>
    </section>
  );
};

const ServiceCard = ({ title, details, icon }) => {
  return (
    <div className="w-full px-4 md:w-1/2 lg:w-1/3">
      <div className="mb-10 rounded-lg bg-white p-6 shadow-md dark:bg-dark-2 shadow-xl serviceCard min-h-[300px] flex flex-col">
        <div className="mb-5 flex h-12 w-12 items-center justify-center rounded-full bg-primary text-white">
          {icon}
        </div>
        <h4 className="mb-3 text-xl font-semibold text-dark dark:text-white">
          {title}
        </h4>
        <p className="text-body-color overflow-y-auto flex-grow">{details}</p>
      </div>
    </div>
  );
};

export default Services;