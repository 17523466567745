import React from "react";

const Statistic = ({ title, desc, icon }) => {
    return (
        <div className="flex flex-col items-center p-6 m-5  rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300" style={{backgroundColor:'#EDF0ED'}}>
            <div className="flex items-center  text-3xl mb-3">
                <div className="mr-2">
                    {icon}
                </div>
                <p className="text-lg font-semibold" style={{color:'#606060'}}>{title}</p>
            </div>
            <div className="text-gray-700 text-2xl font-bold">
                <p style={{color:'#131313'}}>{desc}</p>
            </div>
        </div>
    );
};

export default Statistic;