import React, { useEffect } from "react";
import Hero from "../../components/Hero/Hero";
import About from "../../components/About/About";
import Services from "../../components/Services/Services";
import { useData } from "../../Context/DataContext";
import AppFooter from "../../components/App-Footer/AppFooter";

const HomePage = () => {
    
    // const [data, setData] = useState();
    return (
        <>
            <Hero />
            <div id="services">
                <Services />

            </div>
            <div id="about">

                <About />
            </div>
            <AppFooter />
        </>
        // <h1>Welcome to the Homepage</h1>
    )
}

export default HomePage;