import React, { useState } from "react";
import { FaRegUserCircle } from "react-icons/fa";
import Joi from "joi-browser";
import { Link } from "react-router-dom";
import { useAppRequests } from "../../Network/Requests";
import { LineWave } from "react-loader-spinner";

export default function SignupPage() {
  const {unsecuredPost} = useAppRequests();
  const [data, setData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    password: ''
  });
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({});
  const [submissionMessage, setSubmissionMessage] = useState({
    message: '',
    type: 1 // 1 for success, 0 for error
  });

  const schema = {
    first_name: Joi.string().min(2).label('First Name').required(),
    last_name: Joi.string().min(2).label('Last Name').required(),
    email: Joi.string().email().label('Email').required(),
    phone: Joi.string().min(10).label('Phone').required(),
    password: Joi.string().min(8).label('Password').required()
  };

  const validateForm = () => {
    const validationResult = Joi.validate(data, schema, { abortEarly: false });
    if (validationResult.error) {
      const errorMessages = {};
      validationResult.error.details.forEach(detail => {
        errorMessages[detail.path[0]] = detail.message;
      });
      setErrors(errorMessages);
      return false;
    }
    setErrors({});
    return true;
  };

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const response = await unsecuredPost('/register', data);
      if (!response.success) {
        setSubmissionMessage({ message: response.response.error, type: 0 });
        setLoading(false);
        console.log(response);
      }
      else {

        console.log(response);
        setSubmissionMessage({ message: response.response.message, type: 1 });
        // saveUserData(response.response.token, response.response.user);
        setLoading(false);
      }

    }
  };

  return (
    <>
      <div className="flex min-h-full flex-1 mt-20 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm text-center">
          <div className="flex justify-center">
            <FaRegUserCircle className="text-6xl text-primary" />
          </div>
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Create a new account
          </h2>
        </div>
        {loading ? (
          <div className="flex items-center justify-center text-center ">
          <LineWave
              visible={true}
              height="100"
              width="100"
              color="#4fa94d"
              ariaLabel="line-wave-loading"
              wrapperStyle={{}}
              wrapperClass=""
              firstLineColor=""
              middleLineColor=""
              lastLineColor=""
          />
      </div>
        ):(

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            {submissionMessage.message && (
              <div className={`text-sm text-center ${submissionMessage.type === 1 ? 'text-green-500' : 'text-red-500'}`}>
                {submissionMessage.message}
              </div>
            )}
            {/* <form onSubmit={handleSubmit}> */}
            <div className="flex space-x-4">
              <div className="my-2 w-1/2">
                <label htmlFor="first_name" className="block text-sm font-medium leading-6 text-gray-900">
                  First Name
                </label>
                <div className="mt-2">
                  <input
                    id="first_name"
                    name="first_name"
                    type="text"
                    value={data.first_name}
                    onChange={handleChange}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  />
                </div>
                {errors.first_name && <div className="text-red-500 text-sm">{errors.first_name}</div>}
              </div>
              <div className="my-2 w-1/2">
                <label htmlFor="last_name" className="block text-sm font-medium leading-6 text-gray-900">
                  Last Name
                </label>
                <div className="mt-2">
                  <input
                    id="last_name"
                    name="last_name"
                    type="text"
                    value={data.last_name}
                    onChange={handleChange}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  />
                </div>
                {errors.last_name && <div className="text-red-500 text-sm">{errors.last_name}</div>}
              </div>
            </div>
  
            <div className="my-2">
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={data.email}
                  onChange={handleChange}
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                />
              </div>
              {errors.email && <div className="text-red-500 text-sm">{errors.email}</div>}
            </div>
  
            <div className="my-2">
              <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900">
                Phone 
              </label>
              <div className="mt-2">
                <input
                  id="phone"
                  name="phone"
                  type="phone"
                  value={data.phone}
                  onChange={handleChange}
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                />
              </div>
              {errors.phone && <div className="text-red-500 text-sm">{errors.phone}</div>}
            </div>
  
            <div className="my-2">
              <div className="flex items-center justify-between">
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                  Password
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={data.password}
                  onChange={handleChange}
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                />
              </div>
              {errors.password && <div className="text-red-500 text-sm">{errors.password}</div>}
            </div>
  
            <div>
              <button
                type="submit"
                onClick={handleSubmit}
                className="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 my-4 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
              >
                Create account
              </button>
            </div>
            {/* </form> */}
  
            <p className="mt-10 text-center text-sm text-gray-500">
              Have an account?{' '}
              <Link to="/sign-in" className="font-semibold leading-6 text-primary hover:text-primary-500">
                Login to your account
              </Link>
            </p>
          </div>
        )}
      </div>
    </>
  );
}