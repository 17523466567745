import React, { useEffect, useState } from "react";
import BookingForm from "../../components/Booking-Form/BookingForm";
import './style.css';
import data from '../data.json'
import { useAppRequests } from "../../Network/Requests";

const BookingPage = () => {
    const [formData, setFormData] = useState({
        insuranceCompanies: [],
        workingHours: { start: "", end: "" },
        clinicServices: [],
    });
    const { unsecuredGet } = useAppRequests();

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch insurance companies
                const insuranceResponse = await unsecuredGet('/insurance-companies');
                if (insuranceResponse.success) {
                    const companies = insuranceResponse.response.data.map(company => ({
                        id: company.id,
                        name: company.name
                    }));
                    
                    // Fetch working hours
                    const workingHoursResponse = await unsecuredGet('/working-hours');
                    if (workingHoursResponse.success) {
                        const { start, end } = workingHoursResponse.response.data;
                        
                        setFormData(prevData => ({
                            ...prevData,
                            insuranceCompanies: companies,
                            workingHours: { start, end }
                        }));
                    }

                    //Fetch clinic services
                    const clinicServices = await unsecuredGet('/clinic-services');
                    if (clinicServices.success) {
                        const services = clinicServices.response.data;
                        
                        setFormData(prevData => ({
                            ...prevData,
                            clinicServices :services
                        }));
                    }
                }
                
                
            } catch (error) {
                console.error("Error fetching data:", error);
                // Handle error (e.g., show error message to user)
            }
        };

        fetchData();
    }, []);

    return (
        <div className="booking-container">
            <BookingForm 
                clinicServices={formData.clinicServices}
                workingHours={formData.workingHours} 
                insuranceCompanies={formData.insuranceCompanies}
            />
        </div>
    );
};

export default BookingPage;