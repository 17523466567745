import React, { useEffect, useState } from "react";
import { useData } from "../../Context/DataContext";
import Joi from "joi-browser";
import './style.css';
import { useAppRequests } from "../../Network/Requests";
import AppLoader from "../App-Loader/AppLoader";

const BookingForm = ({ workingHours, insuranceCompanies, clinicServices }) => {
    const { contextData } = useData();
    const { unsecuredGet, unsecuredPost , securedPost, isLoading } = useAppRequests();
    const [disabledHours, setDisabledHours] = useState([]);
    const [bookingMessage, setBookingMessage] = useState({
        message: '',
        type: 1 // 1 for success, 0 for error
    });
    // const [loading, setLoading] = useState(false);
    const initialBookingData = {
        firstName: contextData.loggedIn ? contextData.user.firstName : '',
        lastName: contextData.loggedIn ? contextData.user.lastName : '',
        email: contextData.loggedIn ? contextData.user.email : '',
        phone: contextData.loggedIn ? contextData.user.phone : '',
        date: '',
        time: '',
        insurance_company_id: '',
        service_id :''
    }
    const [bookingData, setBookingData] = useState(initialBookingData);
    const [errors, setErrors] = useState({});

    const schema = {
        firstName: Joi.string().min(3).max(20).label('First Name').required(),
        lastName: Joi.string().min(3).label('Last Name').required(),
        email: Joi.string().email().label('Email').required(),
        phone: Joi.string().min(10).label('Phone').required(),
        date: Joi.date().label('Arrival Date').required(),
        time: Joi.string().label('Arrival Time').required(),
        insurance_company_id: Joi.string().label('Insurance Company').required(),
        service_id: Joi.string().label('Clinic Services').required()
    };

    

    const validateForm = () => {
        
        const validationResult = Joi.validate(bookingData, schema, { abortEarly: false });

        if (validationResult.error) {
            const errorMessages = {};
            validationResult.error.details.forEach(detail => {
                errorMessages[detail.path[0]] = detail.message;
            });
            setErrors(errorMessages);
            console.log(errorMessages);
            return {
                success: false,
                error :errorMessages
            }
        } else {
            setErrors({});
            return {
                success: true
            }
        }
    };

    const getTodayDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    
    const handleChange = (e) => {
        setBookingData({
            ...bookingData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit =async (e)=>{
        e.preventDefault();
        const validateData = validateForm();
        if(validateData.success){
            // setLoading(true);
            console.log(bookingData);
            const loggedIn = contextData.loggedIn; 
            let newBooking;
            if(loggedIn){
                 newBooking = await securedPost('/book-appointment', bookingData, 'bookAppointment');
            }
            else {
                 newBooking = await unsecuredPost('/book-appointment', bookingData , 'bookAppointment');
            }
            if(!newBooking.success){
                setBookingMessage({ message: newBooking.response.error, type: 0 });
                // console.log(newBooking);
                
                // setLoading(false);
                
            }
            else if (newBooking.success) {
                setBookingMessage({ message: newBooking.response.message, type: 1 });
                setBookingData(initialBookingData);
                // setLoading(false);
            }
            
            // Submit booking data
        }
    }

    const handleChangeDate = async (e) => {
        const date = e.target.value;
        
        setBookingData((prevState) => ({
            ...prevState,
            time: "",
            [e.target.name]: date
        }));
    
        try {
            const response = await unsecuredGet(`/booked-hours?date=${date}`);
            if (response.success) {
                const bookedTimes = response.response.data.times.map(time => {
                    const [hours, minutes] = time.split(':');
                    return `${hours.padStart(2, '0')}:${minutes}`;
                });
                setDisabledHours(bookedTimes);
                console.log("Booked hours:", bookedTimes);
            }
        } catch (error) {
            console.error("Error fetching booked hours:", error);
            // Handle error (e.g., show error message to user)
        }
    };

    const generateTimes = () => {
        let times = [];
        for (let hour = parseInt(workingHours.start); hour <= parseInt(workingHours.end); hour++) {
            const time = `${hour.toString().padStart(2, '0')}:00`;
            times.push(time);
        }
        return times;
    }

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm text-center">
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Book an appointment
                </h2>
            </div>
            {isLoading('bookAppointment') ? (
                <AppLoader />
            ):(

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    {bookingMessage.message && (
                            <div
                                className={`text-sm text-center ${bookingMessage.type === 1 ? 'text-green-500' : 'text-red-500'
                                    }`}
                            >
                                {bookingMessage.message}
                            </div>
                        )}
                    <div className="flex">
                        <div className="my-2 mx-2 w-1/2">
                            <label htmlFor="first_name" className="block text-sm font-medium leading-6 text-gray-900">
                                First Name
                            </label>
                            <div className="mt-2">
                                <input
                                    id="first_name"
                                    name="firstName"
                                    type="text"
                                    value={bookingData.firstName}
                                    onChange={handleChange}
                                    required
                                    className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
    
                        <div className="my-2 mx-2 w-1/2">
                            <label htmlFor="last_name" className="block text-sm font-medium leading-6 text-gray-900">
                                Last Name
                            </label>
                            <div className="mt-2">
                                <input
                                    id="last_name"
                                    name="lastName"
                                    type="text"
                                    value={bookingData.lastName}
                                    onChange={handleChange}
                                    required
                                    className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                    </div>
                    {errors.firstName && <div className="text-red-500 text-sm">{errors.firstName}</div>}
                    {errors.lastName && <div className="text-red-500 text-sm">{errors.lastName}</div>}
    
                    <div className="my-2">
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                            Email address
                        </label>
                        <div className="mt-2">
                            <input
                                disabled={contextData.loggedIn && true}
                                id="email"
                                name="email"
                                type="email"
                                value={bookingData.email}
                                onChange={handleChange}
                                required
                                className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                        {errors.email && <div className="text-red-500 text-sm">{errors.email}</div>}
                    </div>
    
                    <div className="my-2">
                        <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900">
                            Phone
                        </label>
                        <div className="mt-2">
                            <input
                                disabled={contextData.loggedIn && true}
                                id="phone"
                                name="phone"
                                type="number"
                                value={bookingData.phone}
                                onChange={handleChange}
                                required
                                className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                        {errors.phone && <div className="text-red-500 text-sm">{errors.phone}</div>}
                    </div>
    
                    <div className="my-2">
                        <label htmlFor="arrival_date" className="block text-sm font-medium leading-6 text-gray-900">
                            Arrival Date
                        </label>
                        <div className="mt-2">
                            <input
                                id="arrival_date"
                                name="date"
                                type="date"
                                min={getTodayDate()}
                                value={bookingData.date}
                                onChange={handleChangeDate}
                                required
                                className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                        {errors.date && <div className="text-red-500 text-sm">{errors.date}</div>}
                    </div>
    
                    <div className="my-2">
                        <label htmlFor="arrival_time" className="block text-sm font-medium leading-6 text-gray-900">
                            Arrival Time
                        </label>
                        <div className="mt-2">
                            <select
                                id="arrival_time"
                                name="time"
                                value={bookingData.time}
                                onChange={handleChange}
                                required
                                className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                            >
                                <option value="">Select a time</option>
                                {generateTimes().map((time, index) => {
                                    // console.log(disabledHours);
                                    
                                    const isDisabled = disabledHours.includes(time);
                                    // console.log(`${time} - ${isDisabled}`)
                                    return (
                                        <option key={index} disabled={isDisabled} value={time}>
                                            {time}
                                        </option>
                                    );
                                })}
                            </select>
                            {errors.time && <div className="text-red-500 text-sm">{errors.time}</div>}
                        </div>
                    </div>
    
                    <div className="my-2">
                        <label htmlFor="clinic_service" className="block text-sm font-medium leading-6 text-gray-900">
                            Clinic Service
                        </label>
                        <div className="mt-2">
                            <select
                                id="clinic_service"
                                name="service_id"
                                value={bookingData.service_id}
                                onChange={handleChange}
                                required
                                className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                            >
                                <option value="">Select a service</option>
                                {clinicServices.map((service, index) => (
                                    <option key={index} value={service.id}>
                                        {service.name}
                                    </option>
                                ))}
                            </select>
                            {errors.service_id && <div className="text-red-500 text-sm">{errors.service_id}</div>}
                        </div>
                    </div>
                    <div className="my-2">
                        <label htmlFor="insurance_company" className="block text-sm font-medium leading-6 text-gray-900">
                            Insurance Company
                        </label>
                        <div className="mt-2">
                            <select
                                id="insurance_company"
                                name="insurance_company_id"
                                value={bookingData.insurance_company_id}
                                onChange={handleChange}
                                required
                                className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                            >
                                <option value="">Select your insurance</option>
                                {insuranceCompanies.map((company, index) => (
                                    <option key={index} value={company.id}>
                                        {company.name}
                                    </option>
                                ))}
                            </select>
                            {errors.insurance_company_id && <div className="text-red-500 text-sm">{errors.insurance_company_id}</div>}
                        </div>
                    </div>
    
                    <div className="mt-5" style={{marginTop:'3rem'}}>
                        <button
                            type="submit"
                            onClick={handleSubmit}
                            className="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 my-4 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
                        >
                            Book Appointment
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BookingForm;